
import axios from "axios";
axios.defaults.withCredentials = true;
export default axios.create({
  baseURL: 'https://api.fx-globals.com',
  headers: {
    "Content-type": "application/json",
    withCredentials: true,
  }
});
