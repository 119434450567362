import React from 'react'

const SignIn = () => {
  return (
    <div className="modal fade" id="signInModal" tabIndex={-1} role="dialog" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content bdr-radius">
      <div className="signin-wrapper">
        <div className="signin-wrapper-header text-center">
          <div className="logo"><img src="assets/images/elements/logo-icon.png" alt="image" /></div>
          <h3 className="title">Login with</h3>
          <p>Welcome back, please sign in below</p>
        </div>
        <form className="signin-form">
          <div className="form-group">
            <label htmlFor="signinEmail">Email*</label>
            <input type="email" className="form-control" id="signinEmail" placeholder="Enter your Email" />
          </div>
          <div className="form-group">
            <label htmlFor="signinPass">Password*</label>
            <input type="password" className="form-control" id="signinPass" placeholder="Password" />
          </div>
          <div className="form-group">
            <div className="custom-checkbox">
              <input type="checkbox" name="id-1" id="id-1" defaultChecked />
              <label htmlFor="id-1">Remember Password</label>
              <span className="checkbox" />
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-hover">Log In</button>
        </form>
        <div className="signin-wrapper-footer">
          <p className="bottom-text">Don’t have an account? <a href="#0" data-toggle="modal" data-target="#signUpModal" data-dismiss="modal" aria-label="Close">Sign Up Now</a></p>
          <div className="divider"><span>or</span></div>
          <ul className="social-list">
            <li><a href="#0"><i className="fa fa-facebook-f" /></a></li>
            <li><a href="#0"><i className="fa fa-twitter" /></a></li>
            <li><a href="#0"><i className="fa fa-linkedin" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default SignIn
