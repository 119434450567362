import React from 'react'
import RealestatePlan from '../components/Home/RealestatePlan'

const Realestate = () => {
  return (
   <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/investment.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Real Estate Investment</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item">Real Estate</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="about-section start pt-120 pb-120">
    <div className="shape-el">
      <img src="assets/images/elements/about.png" alt="image" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <span className="section-subtitle">We Are the best investors in real estate and housing management</span>
              <h2 className="section-title">Real Estate</h2>
              {/* <p>To meet <a href="https://account.fx-globals.com">today's challenges</a>, we've created a unique fund management system</p> */}
            </div>
            <p>As one of the world’s largest investors in real estate, we own and operate iconic properties in the world’s most dynamic markets. Our global portfolio includes office, retail, multifamily, logistics, hospitality, triple net lease, manufactured and geography, reducing exposure to movement in any single market and minimizing volatility. In our public and private investment vehicles, we seek to generate superior returns by leveraging our operating expertise and focusing on our core real estate capabilities—leasing, financing, development, design and construction, and property and facilities management. housing and student housing assets on five continents. Our goal is to generate stable and growing distributions for our investors while protecting them against downside risk. Our assets are diversified by sector</p>
            <a href="https://account.fx-globals.com" className="btn btn-primary btn-hover text-small">get started</a>
          </div>
        </div>

        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <h2 className="section-title">Core/Core-Plus</h2>
            </div>
            <p>Our U.S. and European value-add strategy focuses on undermanaged and under-funded income-producing assets, including multifamily, office, hotel, industrial, and mixed-use retail properties across the United States and Western Europe. The strategy seeks to create value and generate stable and growing distributions to investors by buying properties at attractive valuations, implementing asset management initiatives to increase income and identifying multiple exit strategies upfront.
            </p>
            
            <div className="section-header">
              <h2 className="section-title">Value-Add</h2>
            </div>
            <p>Our U.S. and European opportunistic real estate strategy capitalizes on increased investor demand for developed and stabilized assets by focusing on the repositioning of asserts, capitalization of distressed and special situations, and development of core-quality assets across all major property types including multifamily, hotel, office, retail and industrial properties throughout the United States and Europe.


            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <RealestatePlan/>
</div>

  )
}

export default Realestate
