import React from 'react'

const Deposit = () => {
  return (
    <section style={{backgroundColor: '#055b42'}} className="deposit-withdraw-section pt-120 pb-120 section-md-bg">
  <div className="circle-object"><img src="assets/images/elements/deposit-circle-shape.png" alt="image" /></div>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center">
          <span className="section-subtitle text-white">Convenient money</span>
          <h2 className="section-title text-white">Deposit &amp; Withdrawal</h2>
          <p className='text-white'>Depositing or withdrawing money is simple.We support several payment methods, which depend on what country your payment account is located in.</p>
        </div>
      </div>
    </div>
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="dep-wth-option-area wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <span className="circle one" />
          <span className="circle two" />
          <span className="circle three" />
          <span className="circle four" />
          <a href="#0" className="card-item">
            <span className="icon"><img src="assets/images/icons/payment-option/card.svg" alt="image" /></span>
            <span className="caption">Credit Card</span>
          </a>{/* card-item end */}
          <a href="#0" className="card-item">
            <span className="icon"><img src="assets/images/icons/payment-option/paypal.svg" alt="image" /></span>
            <span className="caption">Credit Card</span>
          </a>{/* card-item end */}
          <a href="#0" className="card-item">
            <span className="icon"><img src="assets/images/icons/payment-option/bitcoin.svg" alt="image" /></span>
            <span className="caption">Credit Card</span>
          </a>{/* card-item end */}
          <a href="#0" className="card-item">
            <span className="icon"><img src="assets/images/icons/payment-option/litecoin.svg" alt="image" /></span>
            <span className="caption">Credit Card</span>
          </a>{/* card-item end */}
          <a href="#0" className="card-item">
            <span className="icon"><img src="assets/images/icons/payment-option/ethereum.svg" alt="image" /></span>
            <span className="caption">Credit Card</span>
          </a>{/* card-item end */}
          <a href="#0" className="card-item">
            <span className="icon"><img src="assets/images/icons/payment-option/ripple.svg" alt="image" /></span>
            <span className="caption">Credit Card</span>
          </a>{/* card-item end */}
          <a href="#0" className="text-btn">view all option</a>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="feature-item">
          <div className="icon">
            <div className="icon-inner">
              <img src="assets/images/icons/payment-option/ft1.svg" alt="icon" />
            </div>
          </div>
          <div className="content">
            <h3 className="title">No Limits</h3>
            <p>Unlimited maximum withdrawal amount</p>
          </div>
        </div>{/* feature-item end */}
        <div className="feature-item">
          <div className="icon">
            <div className="icon-inner">
              <img src="assets/images/icons/payment-option/ft2.svg" alt="icon" />
            </div>
          </div>
          <div className="content">
            <h3 className="title">Withdrawal in 24 /7</h3>
            <p>Deposit – instantaneous</p>
          </div>
        </div>{/* feature-item end */}
      </div>
    </div>
  </div>
</section>


  )
}

export default Deposit
