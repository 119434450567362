import React, {useEffect, useState} from 'react'
import apiClient from '../../request/http-common';

const Transactions = () => {
  const convertToString = (text) => text.toString();
  const [withdrawals, setwithdrawals] = useState([]);
  const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

  const { uniqueNamesGenerator, names, NumberDictionary } = require('unique-names-generator');
  const randomName = uniqueNamesGenerator({ dictionaries: [names] });

  const loopList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
    apiClient.get('/api/investment-plans')
        .then((response) => {
          if(response.data.status === 'success'){
             setwithdrawals(response.data.withdrawals);        
          }
        });
  }, []);

  return (
    <section className="latest-transaction-section pt-120 pb-120" style={{backgroundColor: '#041812'}}>
    
  <div className="elemets-bg" data-paroller-factor="-0.2" data-paroller-type="foreground" data-paroller-direction="vertical"><img src="assets/images/withdraw-bg.png" alt /></div>
  {loopList.length > 0 &&
    
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center">
          <span className="section-subtitle text-white">Latest Transaction</span>
          <h2 className="section-title text-white">Withdrawals</h2>
          <p style={{color: '#e5f3ef'}}>Our goal is to simplify investing so that anyone can be an investor.Withthis in mind, we  hand-pick the investments we offer on our platform.</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="tab-content" id="transactionTabContent">
          <div className="tab-pane fade show active" id="daily" role="tabpanel" aria-labelledby="daily-tab">
            <div className="withdraw-table-area">
              <table>
                <thead>
                  <tr>
                    <th>Investor</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loopList.map(index => (
                   <tr>

                   <td>
                     <div className="person-details">
                       <div className="thumb"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643714613/profile.png" alt="image" /></div>
                       <div className="content">
                         <span className="name">{uniqueNamesGenerator({ dictionaries: [names] })}</span>
                       </div>
                     </div>
                   </td>

                   <td>
                     <span className="price">
                     {getAmount(NumberDictionary.generate({ min: 50, max: 50000 }))}
                    </span>
                   </td>
                   
                   <td>
                     <span className="daily-dividend">Approved</span>
                   </td>
                  
                 </tr>
                 ))}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="btn-area text-center">
          <a href="#0" className="btn btn-primary btn-hover text-small">browse more</a>
        </div>
      </div>
    </div>
  </div>
}
</section>


  )
}

export default Transactions
