import React from 'react'

const Offers = () => {
  return (
    <section style={{backgroundColor: '#092918', backgroundImage: 'url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1694430747/features-bg.png)', backgroundRepeat: 'no-repeat'}} className="community-section pt-120 pb-120">
  <div className="object anim-bounce"><img src="assets/images/elements/community-bg-object.png" alt="image" /></div>
  <div className="shape"><img src="assets/images/elements/community-bg-shpe.png" alt="image" /></div>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <div className="section-header text-center">
          <span className="section-subtitle">Our Services</span>
          <h2 className="section-title" style={{color: '#fff'}}>What We Do</h2>
          <p style={{color: '#ecf8f5'}}>Access a world of dynamic investment opportunities, buy into businesses you believe in and share in their  success.You may make additional deposits at any time. All our payments are instant payments.</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-7 offset-lg-5">
        <div className="row mb-none-30" >
          <div className="col-lg-8">
            <div style={{backgroundColor: '#086047'}} className="community-item community-item--style2 mb-30">
              <div className="icon">
                <img src="assets/images/icons/community/bitcoin-true.png" alt="image" />
              </div>
              <div className="content">
                <h3 className="title text-white">Cryptocurrency And Forex</h3>
                <p style={{color: '#e4f1ed'}}>Seamless trading experience</p>
                <a href="/real-estate" className="read-more-btn" style={{color: '#b8f5e4'}}>read more<i className="icofont-long-arrow-right" /></a>
              </div>
            </div>{/* community-item end */}
          </div>
          <div className="col-lg-8 offset-lg-2">
            <div style={{backgroundColor: '#086047'}} className="community-item community-item--style2 mb-30">
              <div className="icon">
                <img src="assets/images/icons/community/building-alt.png" alt="image" />
              </div>
              <div className="content">
                <h3 className="title text-white">Real Estate</h3>
                <p style={{color: '#e4f1ed'}}>Invest in real estate opportunities worldwide </p>
                <a href="/real-estate" className="read-more-btn" style={{color: '#b8f5e4'}}>read more<i className="icofont-long-arrow-right" /></a>
              </div>
            </div>{/* community-item end */}
          </div>
          <div className="col-lg-8 offset-lg-4">
            <div style={{backgroundColor: '#086047'}} className="community-item community-item--style2 mb-30">
              <div className="icon">
                <img src="assets/images/icons/community/football.png" alt="image" />
              </div>
              <div className="content">
                <h3 className="title text-white">Sports</h3>
                <p style={{color: '#e4f1ed'}}>Invest in sports today worldwide</p>
                <a href="/real-estate" className="read-more-btn" style={{color: '#b8f5e4'}}>read more<i className="icofont-long-arrow-right" /></a>
              </div>
            </div>{/* community-item end */}
          </div>
        </div>
      </div>
    </div>
  </div>
  
</section>

  )
}

export default Offers
